import { useCallback, useMemo } from "react";
import { get, some } from "lodash";
import { useSelector } from "react-redux";
import { OnChange, OnFocus } from "react-final-form-listeners";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { locationsApis } from "../../../apis";
import { Fields } from "../../../constants/forms";
import { useOverlay } from "../../../features/Overlay";
import { WizardForm } from "../../../features/Wizard";
import { Validators, ErrorUtil } from "../../../utils";
import styles from "../AddNewAddress.module.scss";
import * as M from "../../../constants/strings";
import Template from "../Template";
import { useToaster } from "../../../features/Toaster";
import AddressFields from "../../../components/organisms/DeliveryAddress/AddressFields";
import AddressLabel from "../../../components/organisms/AddressLabel";
import FormSection from "../../../components/molecules/FormSection/FormSection";
import Input from "../../../components/atoms/Input";
import Toggle from "../../../components/atoms/Toggle";
import ConciergeToggle from "../../../components/atoms/ConciergeToggle";
import { Field } from "react-final-form";
import ImageDropzone from "../../../components/molecules/ImageDropzone";
import {
  ACCEPT_IMAGE_MIME_TYPE,
  MAX_FILE_SIZE,
} from "../../../constants/safePlace";
import { ADDRESS_TYPE_NAME } from "../../../constants";
import { ConsumerAddressesSelector } from "../../../features/Profile";
import { useAbortController } from "../../../hooks/useAbortController";
import { useRemoteConfig } from "../../../features/Config";
import { ADD_ADDRESS } from "../../../constants/analytics";

const initialState = {
  [Fields.UDPRN]: null,
  [Fields.POSTCODE]: null,
  [Fields.TYPE]: ADDRESS_TYPE_NAME.HOME,
  [Fields.NOTE]: null,
  [Fields.IMAGE_URL]: null,
  [Fields.NEED_MORE_TIME]: false,
  [Fields.CONCIERGE]: false,
};

const DeliveryAddressStep = ({
  values,
  references,
  setReferences,
  nextStep,
  setValues,
}) => {
  const overlay = useOverlay();
  const toaster = useToaster();
  const tracker = useTracker();

  const initialValues = useMemo(
    () => ({
      ...initialState,
      ...values,
    }),
    []
  );

  const { needMoreTime } = useRemoteConfig();

  const addresses = useSelector(ConsumerAddressesSelector.getConsumerAddresses);

  const signalAbort = useAbortController();

  const onChangePostcode = useCallback(
    async (value, form) => {
      tracker.logEvent(ADD_ADDRESS.ON_POSTCODE_INPUT);

      try {
        if (Validators.isPostcodeValid(value)) {
          form.change(Fields.UDPRN, null);
          overlay.show();
          const { data } = await locationsApis.searchAddressesByPostcode(
            value,
            {
              signal: signalAbort(),
            }
          );

          if (data && data.length) {
            setReferences("addresses", data);
            return;
          } else {
            toaster.showError({
              body: M.UNABLE_TO_FIND_ADDRESS_WITH_POSTCODE,
            });
          }
        }

        setReferences("addresses", []);
      } catch (e) {
        setReferences("addresses", []);

        if (!ErrorUtil.isIgnorableNetworkError(e)) {
          toaster.showError({
            body: get(e, "errors[0].message") || e.message,
          });
        }
      } finally {
        overlay.hide();
      }
    },
    [signalAbort, overlay, toaster, setReferences, tracker]
  );

  const onClickSkip = useCallback(() => {
    setValues(initialState);
    nextStep();
  }, [nextStep, setValues]);

  return (
    <Trackable interfaceId={ADD_ADDRESS.INTERFACE_ID} loadId={ADD_ADDRESS.LOAD}>
      <WizardForm
        initialValues={initialValues}
        onSubmit={() => {
          tracker.logEvent(ADD_ADDRESS.ON_NEXT);
          nextStep();
        }}
        formClassName={styles.stepContent}
        validate={(values) => {
          const errors = {};
          if (get(values, Fields.UDPRN)) {
            const addressAlreadyExists = some(addresses, [
              Fields.UDPRN,
              values[Fields.UDPRN],
            ]);
            if (addressAlreadyExists) {
              errors[Fields.UDPRN] = M.THIS_ADDRESS_HAS_ALREADY_BEEN_ADDED;
            }
          }

          return errors;
        }}
      >
        {({ form }) => (
          <Template required onClickSkip={onClickSkip} className="px-0 py-0">
            <FormSection title={M.YOUR_DELIVERY_ADDRESS}>
              <AddressFields
                form={form}
                addresses={references.addresses || []}
                onChangePostcode={onChangePostcode}
                onFocusAddress={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_CLICK_ADDRESS_DROPDOWN);
                }}
                onSelectAddress={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_ADDRESS_SELECT);
                }}
                onCantFindAddress={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_CANT_FIND_ADDRESS);
                }}
              />
            </FormSection>
            <hr className="m-0" />
            <FormSection title={M.CHOOSE_YOUR_NAME_OF_ADDRESS}>
              <AddressLabel
                onSelectType={(type) => {
                  tracker.logEvent(
                    type === ADDRESS_TYPE_NAME.WORK
                      ? ADD_ADDRESS.ON_SELECT_WORK_TYPE
                      : ADD_ADDRESS.ON_SELECT_HOME_TYPE
                  );
                }}
                onCustomTypeChange={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_CHANGE_CUSTOM_TYPE);
                }}
              />
              <OnFocus name={Fields.CUSTOM_ADDRESS_TYPE}>
                {() => {
                  tracker.logEvent(ADD_ADDRESS.ON_CLICK_CUSTOM_TYPE);
                }}
              </OnFocus>
            </FormSection>
            <hr className="m-0" />
            <FormSection title={M.IS_PROPERTY_ACCESS_THROUGH_CONCIERGE}>
              <Field name={Fields.CONCIERGE} component={ConciergeToggle} />
            </FormSection>
            <hr className="m-0" />
            <FormSection title={M.ADD_NOTE} subtitle={M.ADD_NOTE_MESSAGE}>
              <Field
                name={Fields.NOTE}
                component={Input}
                placeholder={M.ENTER_NOTE}
                as="textarea"
                maxLength={255}
                validate={Validators.composeValidators(
                  Validators.maxLength(255),
                  Validators.notEmpty
                )}
              />
            </FormSection>
            <hr className="m-0" />
            <FormSection
              title={M.ADD_PHOTO}
              subtitle={
                values[Fields.CONCIERGE]
                  ? M.ADD_YOUR_PROPERTY_OR_RECEPTION_PHOTO
                  : M.ADD_YOUR_PROPERTY_PHOTO
              }
            >
              <Field
                name={Fields.IMAGE_URL}
                component={ImageDropzone}
                accept={ACCEPT_IMAGE_MIME_TYPE}
                maxSize={MAX_FILE_SIZE} // 10Mb
                title={M.YOUR_PROPERTY_IMAGE}
                hint={M.ACCEPT_SAFE_PLACE_IMAGE_HINT}
                placeholder={M.DRAG_AND_DROP_FILE_HERE}
                onUploadClick={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_IMAGE_UPLOAD);
                }}
                onDeleteClick={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_DELETE_IMAGE);
                }}
                onImagePreviewClick={() => {
                  tracker.logEvent(ADD_ADDRESS.ON_IMAGE_CLICK);
                }}
              />
            </FormSection>
            <hr className="m-0" />
            {needMoreTime && (
              <FormSection
                title={M.MORE_TIME_NEEDED}
                subtitle={M.MORE_TIME_NEEDED_MESSAGE}
                direction={"horizontal"}
                classes={{
                  subtitle: "m-0",
                }}
              >
                <Field
                  name={Fields.NEED_MORE_TIME}
                  component={Toggle}
                  type="switch"
                />
                <OnChange name={Fields.NEED_MORE_TIME}>
                  {() => {
                    tracker.logEvent(ADD_ADDRESS.ON_MORE_TIME_TOGGLE);
                  }}
                </OnChange>
              </FormSection>
            )}
          </Template>
        )}
      </WizardForm>
    </Trackable>
  );
};

export default DeliveryAddressStep;
