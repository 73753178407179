import { useCallback, useMemo, useState } from "react";
import { get } from "lodash";
import { useTracker } from "@dpdgroupuk/react-event-tracker";

import DeliveryAddress from "../../../components/organisms/DeliveryAddress";
import { locationsApis } from "../../../apis";
import { Fields } from "../../../constants/forms";
import { useOverlay } from "../../../features/Overlay";
import { WizardForm } from "../../../features/Wizard";
import NavigationBar from "../../../components/molecules/WizardNavigationBar";
import { Validators, ErrorUtil } from "../../../utils";
import { useAuth } from "../../../features/Auth";
import styles from "../Onboarding.module.scss";
import { Loader } from "../../../components/molecules/Loader";
import * as M from "../../../constants/strings";
import { Address } from "../../../components/atoms/icons";
import Template from "../../../components/templates/OnboardingStepContainer";
import OnboardingTemplate from "../../../components/templates/Onboarding/Onboarding";
import { useToaster } from "../../../features/Toaster";
import { useAbortController } from "../../../hooks/useAbortController";
import {
  CONFIRM_DELIVERY_ADDRESS_ONBOARDING,
  DELIVERY_ADDRESS_ONBOARDING,
} from "../../../constants/analytics";
import { ADDRESS_TYPE_NAME } from "../../../constants";

const initialValues = {
  [Fields.TYPE]: ADDRESS_TYPE_NAME.HOME,
  [Fields.CONCIERGE]: false,
};

const DeliveryAddressStep = ({
  values,
  references,
  setReferences,
  nextStep,
}) => {
  const overlay = useOverlay();
  const auth = useAuth();
  const toaster = useToaster();
  const [isEdit, setIsEdit] = useState(false);
  const tracker = useTracker();

  const initialUdprn = useMemo(
    () => values[Fields.UDPRN] || auth.currentSession?.udprn,
    []
  );

  const signalAbort = useAbortController();

  const onChangePostcode = useCallback(
    async (value, form) => {
      try {
        if (Validators.isPostcodeValid(value)) {
          tracker.logEvent(DELIVERY_ADDRESS_ONBOARDING.ON_POSTCODE_INPUT);

          form.change(Fields.UDPRN, null);
          overlay.show();
          const { data } = await locationsApis.searchAddressesByPostcode(
            value,
            {
              signal: signalAbort(),
            }
          );

          if (data && data.length) {
            setReferences("addresses", data);
            return;
          } else {
            toaster.showError({
              body: M.UNABLE_TO_FIND_ADDRESS_WITH_POSTCODE,
            });
          }
        }

        setReferences("addresses", []);
      } catch (e) {
        setReferences("addresses", []);

        if (!ErrorUtil.isIgnorableNetworkError(e)) {
          toaster.showError({
            body: get(e, "errors[0].message") || e.message,
          });
        }
      } finally {
        overlay.hide();
      }
    },
    [overlay, signalAbort, toaster, setReferences, tracker]
  );

  const loadReferences = useCallback(
    async ({ udprn, concierge, type }, options) => {
      const newReferences = { ...references };
      let currentAddress = (newReferences.addresses || []).find(
        (a) => a.udprn === udprn
      );

      if (!currentAddress) {
        const { data } = await locationsApis.getAddressByUdprn(udprn, options);
        currentAddress = data;

        const { data: addresses } = await locationsApis.searchLocations(
          currentAddress.postcode,
          options
        );
        setReferences("addresses", addresses);
      }
      return { ...currentAddress, concierge, type };
    },
    []
  );

  const handleEdit = useCallback(() => {
    tracker.logEvent(CONFIRM_DELIVERY_ADDRESS_ONBOARDING.ON_EDIT);
    setIsEdit(true);
  }, [setIsEdit, tracker]);

  const handleSubmit = useCallback(() => {
    tracker.logEvent(
      isEdit
        ? DELIVERY_ADDRESS_ONBOARDING.ON_NEXT
        : CONFIRM_DELIVERY_ADDRESS_ONBOARDING.ON_NEXT
    );
    nextStep();
  }, [tracker, nextStep, isEdit]);

  const handleBack = useCallback(() => {
    tracker.logEvent(
      isEdit
        ? DELIVERY_ADDRESS_ONBOARDING.ON_BACK
        : CONFIRM_DELIVERY_ADDRESS_ONBOARDING.ON_BACK
    );
  }, [tracker, isEdit]);

  const onFocusAddress = useCallback(() => {
    tracker.logEvent(DELIVERY_ADDRESS_ONBOARDING.ON_CLICK_ADDRESS_DROPDOWN);
  }, [tracker]);

  const onSelectAddress = useCallback(
    (value) => {
      if (value) {
        tracker.logEvent(DELIVERY_ADDRESS_ONBOARDING.ON_ADDRESS_SELECT);
      }
    },
    [tracker]
  );

  const onCantFindAddress = useCallback(() => {
    tracker.logEvent(DELIVERY_ADDRESS_ONBOARDING.ON_CANT_FIND_ADDRESS);
  }, [tracker]);

  return (
    <OnboardingTemplate>
      <Loader
        promiseFn={loadReferences}
        udprn={initialUdprn}
        concierge={values[Fields.CONCIERGE] || initialValues[Fields.CONCIERGE]}
        type={values[Fields.TYPE] || initialValues[Fields.TYPE]}
      >
        {(currentAddress) => (
          <WizardForm
            initialValues={currentAddress}
            onSubmit={handleSubmit}
            onClickBack={handleBack}
            Nav={NavigationBar}
            formClassName={styles.stepContent}
          >
            {({ form }) => (
              <Template
                title={M.YOUR_DELIVERY_ADDRESS}
                subtitle={M.WE_KNOW_YOUR_ADDRESS}
                icon={Address}
              >
                <DeliveryAddress
                  udprn={values.udprn}
                  addresses={references.addresses || []}
                  onChangePostcode={onChangePostcode}
                  form={form}
                  onEdit={handleEdit}
                  editMode={isEdit}
                  onFocusAddress={onFocusAddress}
                  onSelectAddress={onSelectAddress}
                  onCantFindAddress={onCantFindAddress}
                />
              </Template>
            )}
          </WizardForm>
        )}
      </Loader>
    </OnboardingTemplate>
  );
};

export default DeliveryAddressStep;
