import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTracker } from "@dpdgroupuk/react-event-tracker";

import * as M from "../../../constants/strings";
import Template from "../Template";
import { getActiveDeliveryAddress } from "../selectors";
import styles from "./PinpointLocationStep.module.scss";
import {
  PinPointLocation,
  getNullObject,
  useInitValues,
} from "../../../features/Preferences/PinPointLocation";
import { WizardForm } from "../../../features/Wizard";
import { PINPOINT_TAB } from "../../../features/Preferences/PinPointLocation/constants";
import {
  ADD_ADDRESS_PINPOINT,
  ADD_ADDRESS_WHAT3WORDS,
} from "../../../constants/analytics";
import { getDeliveryAddressValues } from "../selectors";

const PinpointLocationStep = ({ nextStep, setValues, values }) => {
  const { concierge } = useSelector(getDeliveryAddressValues);
  const initialValues = useInitValues(values);
  const deliveryAddress = useSelector(getActiveDeliveryAddress);
  const [tab, setTab] = useState(PINPOINT_TAB.CHOOSE_LOCATION);
  const tracker = useTracker();

  const onClickSkip = useCallback(() => {
    tracker.logEvent(
      tab === PINPOINT_TAB.WHAT_3_WORDS
        ? ADD_ADDRESS_WHAT3WORDS.ON_SETUP_LATER
        : ADD_ADDRESS_PINPOINT.ON_SETUP_LATER
    );
    setValues(getNullObject());
    nextStep();
  }, [nextStep, setValues, tracker, tab]);

  const handleTabChange = useCallback(
    (tabKey) => {
      setTab(tabKey);
      tracker.logEvent(
        tabKey === PINPOINT_TAB.CHOOSE_LOCATION
          ? ADD_ADDRESS_WHAT3WORDS.ON_PINPOINT_LOCATION
          : ADD_ADDRESS_PINPOINT.ON_WHAT3WORDS
      );
    },
    [setTab, tracker]
  );

  const handleSubmit = useCallback(() => {
    tracker.logEvent(
      tab === PINPOINT_TAB.WHAT_3_WORDS
        ? ADD_ADDRESS_WHAT3WORDS.ON_NEXT
        : ADD_ADDRESS_PINPOINT.ON_NEXT
    );
    nextStep();
  }, [nextStep, tracker, tab]);

  const handleClickBack = useCallback(() => {
    tracker.logEvent(
      tab === PINPOINT_TAB.WHAT_3_WORDS
        ? ADD_ADDRESS_WHAT3WORDS.ON_BACK
        : ADD_ADDRESS_PINPOINT.ON_BACK
    );
  }, [tracker, tab]);

  return (
    <WizardForm initialValues={initialValues} onSubmit={handleSubmit}>
      <Template
        title={M.PINPOINT_LOCATION}
        subtitle={concierge ? M.SET_CONCIERGE_LOCATION : M.SET_EXACT_LOCATION}
        className={styles.pinPointLocation}
        onClickSkip={onClickSkip}
        onClickBack={handleClickBack}
      >
        <Row className="g-0">
          <Col className="justify-content-center">
            <PinPointLocation
              deliveryAddress={deliveryAddress}
              initialValues={initialValues}
              onTabChange={handleTabChange}
              analytics={{
                [PINPOINT_TAB.CHOOSE_LOCATION]: ADD_ADDRESS_PINPOINT,
                [PINPOINT_TAB.WHAT_3_WORDS]: ADD_ADDRESS_WHAT3WORDS,
              }}
            />
          </Col>
        </Row>
      </Template>
    </WizardForm>
  );
};

export default PinpointLocationStep;
