import React, { useCallback, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTracker } from "@dpdgroupuk/react-event-tracker";

import * as M from "../../../constants/strings";
import Template from "../../../components/templates/OnboardingStepContainer";
import { Location } from "../../../components/atoms/icons";
import NavigationBar from "../../../components/molecules/WizardNavigationBar/WizardNavigationBar";
import OnboardingTemplate from "../../../components/templates/Onboarding/Onboarding";
import {
  getActiveDeliveryAddress,
  getDeliveryAddressValues,
} from "../selectors";
import { getAddressPoint } from "../../../models/address";
import styles from "./PinpointLocationStep.module.scss";
import {
  PinPointLocation,
  useInitValues,
  getNullObject,
} from "../../../features/Preferences/PinPointLocation";
import { WizardForm } from "../../../features/Wizard";
import {
  PINPOINT_LOCATION_ONBOARDING,
  WHAT3WORDS_ONBOARDING,
} from "../../../constants/analytics";
import { PINPOINT_TAB } from "../../../features/Preferences/PinPointLocation/constants";

const PinpointLocationStep = ({ nextStep, setValues, values }) => {
  const initialValues = useInitValues(values);
  const deliveryAddress = useSelector(getActiveDeliveryAddress);
  const concierge = useSelector(getDeliveryAddressValues).concierge;
  const addressPoint = useMemo(
    () => getAddressPoint(deliveryAddress),
    [deliveryAddress]
  );
  const [tab, setTab] = useState(PINPOINT_TAB.CHOOSE_LOCATION);
  const tracker = useTracker();

  const handleClickSetupLater = useCallback(() => {
    tracker.logEvent(
      tab === PINPOINT_TAB.WHAT_3_WORDS
        ? WHAT3WORDS_ONBOARDING.ON_SETUP_LATER
        : PINPOINT_LOCATION_ONBOARDING.ON_SETUP_LATER
    );
    setValues(getNullObject());
    nextStep();
  }, [setValues, nextStep, tracker, tab]);

  const handleTabChange = useCallback(
    (tabKey) => {
      setTab(tabKey);
      tracker.logEvent(
        tabKey === PINPOINT_TAB.CHOOSE_LOCATION
          ? WHAT3WORDS_ONBOARDING.ON_PINPOINT_LOCATION
          : PINPOINT_LOCATION_ONBOARDING.ON_WHAT3WORDS
      );
    },
    [setTab, tracker]
  );

  const handleSubmit = useCallback(() => {
    tracker.logEvent(
      tab === PINPOINT_TAB.WHAT_3_WORDS
        ? WHAT3WORDS_ONBOARDING.ON_NEXT
        : PINPOINT_LOCATION_ONBOARDING.ON_NEXT
    );
    nextStep();
  }, [nextStep, tracker, tab]);

  const handleClickBack = useCallback(() => {
    tracker.logEvent(
      tab === PINPOINT_TAB.WHAT_3_WORDS
        ? WHAT3WORDS_ONBOARDING.ON_BACK
        : PINPOINT_LOCATION_ONBOARDING.ON_BACK
    );
  }, [tracker, tab]);

  return (
    <OnboardingTemplate>
      <WizardForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        Nav={NavigationBar}
        formClassName={styles.stepContent}
        onClickBack={handleClickBack}
      >
        <Template
          fluid
          title={M.PINPOINT_LOCATION}
          subtitle={concierge ? M.SET_CONCIERGE_LOCATION : M.SET_EXACT_LOCATION}
          icon={Location}
          className={styles.pinPointLocation}
          onSetupLaterClick={addressPoint && handleClickSetupLater}
        >
          <Row>
            <Col className="justify-content-center">
              <PinPointLocation
                deliveryAddress={deliveryAddress}
                initialValues={initialValues}
                onTabChange={handleTabChange}
                analytics={{
                  [PINPOINT_TAB.CHOOSE_LOCATION]: PINPOINT_LOCATION_ONBOARDING,
                  [PINPOINT_TAB.WHAT_3_WORDS]: WHAT3WORDS_ONBOARDING,
                }}
              />
            </Col>
          </Row>
        </Template>
      </WizardForm>
    </OnboardingTemplate>
  );
};

export default PinpointLocationStep;
