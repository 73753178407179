import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field } from "react-final-form";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import ConciergeToggle from "../../components/atoms/ConciergeToggle";
import ProfilePage from "../../components/templates/ProfilePage";
import { EDIT_ADDRESS_TYPE } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import {
  CONCIERGE,
  CHANGES_SAVED,
  IS_PROPERTY_ACCESS_THROUGH_CONCIERGE,
} from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import EditProfileForm from "../../components/organisms/EditProfileForm";

const EditConcierge = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const { udprn } = useParams();
  const tracker = useTracker();

  const address = useSelector(
    ConsumerAddressesSelector.getConsumerAddressById(udprn)
  );

  const onClickBack = useCallback(() => {
    tracker.logEvent(EDIT_ADDRESS_TYPE.ON_CLICK_BACK);
  }, [tracker]);

  const handleSaveType = useCallback(
    async ({ concierge }, form) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_ADDRESS_TYPE.ON_CLICK_SAVE);

        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            {
              udprn,
              concierge: concierge,
            },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: CHANGES_SAVED,
          autoHide: true,
        });
        form.reset();
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, tracker]
  );

  return (
    <ProfilePage
      title={CONCIERGE}
      subtitle={IS_PROPERTY_ACCESS_THROUGH_CONCIERGE}
      showBackButtonDesktop
      onClickBack={onClickBack}
    >
      <EditProfileForm
        initialValues={{ [Fields.CONCIERGE]: !!address[Fields.CONCIERGE] }}
        onSubmit={handleSaveType}
      >
        <Field
          name={Fields.CONCIERGE}
          component={ConciergeToggle}
          classes={{ container: "p-4" }}
        />
      </EditProfileForm>
    </ProfilePage>
  );
};

export default EditConcierge;
