import PropTypes from "prop-types";
import { ToggleButton } from "react-bootstrap";
import classNames from "classnames";

import styles from "./ToggleButton.module.scss";

const CustomToggleButton = ({ label, isSelected, onClick }) => (
  <ToggleButton
    variant={`${isSelected ? "outline-danger" : "outline-dark"} p-1`}
    className={classNames(
      isSelected ? styles.buttonSelected : styles.button,
      "w-100"
    )}
    onClick={onClick}
  >
    {label}
  </ToggleButton>
);

CustomToggleButton.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CustomToggleButton;
