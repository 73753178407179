import { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import ToggleButton from "../../atoms/ToggleButton";
import Input from "../../atoms/Input";
import ContentHeader from "../ContentHeader";
import { ADDRESS_TYPE_NAME } from "../../../constants/address";
import * as M from "../../../constants/strings";

const CustomAddressNameInput = ({
  input,
  meta,
  maxLength,
  setIsInputFocused,
}) => {
  const [touched, setTouched] = useState(false);

  return (
    <Row>
      <Col>
        <Input
          label={M.ENTER_ADDRESS}
          placeholder={M.ADDRES_NAME_EXAMPLE}
          input={input}
          meta={{
            ...meta,
            touched,
          }}
          classes={{ label: "mt-4 mb-2" }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          onChange={(value) => {
            input.onChange(value);
            setTouched(true);
          }}
          maxLength={maxLength}
        />
      </Col>
    </Row>
  );
};

CustomAddressNameInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  maxLength: PropTypes.number,
  setIsInputFocused: PropTypes.func,
};

const AddressNameInput = ({ input, meta, classes = {}, maxLength }) => {
  const isDefaultValue =
    input.value === ADDRESS_TYPE_NAME.HOME ||
    input.value === ADDRESS_TYPE_NAME.WORK;

  const [isOtherSelected, setIsOtherSelected] = useState(
    input.value && !isDefaultValue
  );
  const [isInputFocused, setIsInputFocused] = useState(false);
  const showOtherInput =
    isOtherSelected && (isInputFocused || (!isInputFocused && !isDefaultValue));

  return (
    <Form.Group controlId={input?.name} className={classes.container}>
      <Form.Label className="w-100 my-3">
        <ContentHeader
          title={M.ADDRESS_NAME}
          subtitle={M.SELECT_ADDRESS_NAME}
          classes={{
            title: "h5 text-center mb-2",
            subtitle: "text-center mb-0",
          }}
        />
      </Form.Label>
      <Row className={classes.buttonsContainer}>
        <Col xs={4} sm={4}>
          <ToggleButton
            label={ADDRESS_TYPE_NAME.HOME}
            isSelected={
              input.value === ADDRESS_TYPE_NAME.HOME && !isInputFocused
            }
            onClick={() => input.onChange(ADDRESS_TYPE_NAME.HOME)}
          />
        </Col>
        <Col xs={4} sm={4}>
          <ToggleButton
            label={ADDRESS_TYPE_NAME.WORK}
            isSelected={
              input.value === ADDRESS_TYPE_NAME.WORK && !isInputFocused
            }
            onClick={() => input.onChange(ADDRESS_TYPE_NAME.WORK)}
          />
        </Col>
        <Col xs={4} sm={4}>
          <ToggleButton
            label={ADDRESS_TYPE_NAME.OTHER}
            isSelected={showOtherInput}
            onClick={() => {
              input.onChange("");
              setIsOtherSelected(true);
            }}
          />
        </Col>
      </Row>
      {showOtherInput && (
        <CustomAddressNameInput
          input={input}
          meta={meta}
          maxLength={maxLength}
          setIsInputFocused={setIsInputFocused}
        />
      )}
    </Form.Group>
  );
};

AddressNameInput.propTypes = {
  input: PropTypes.object,
  classes: PropTypes.object,
  maxLength: PropTypes.number,
};

export default AddressNameInput;
