import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import * as Paths from "./constants";
import NoMatch from "../pages/NoMatch";
import SomethingWentWrong from "../pages/SomethingWentWrong";
import SignIn, { SignInLayout } from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Onboarding from "../pages/Onboarding";
import VerifyEmail from "../pages/VerifyEmail";
import { ProfileLayout } from "../pages/Profile";
import { ProtectedRoute, SESSION_TYPE } from "../features/Auth";
import AccountOverview from "../pages/AccountOverview";
import DeliveryAddresses from "../pages/DeliveryAddresses";
import DeliveryAddressPinPoint from "../pages/DeliveryAddressPinPoint";
import PersonalInformation from "../pages/PersonalInformation";
import ContactYou from "../pages/ContactYou";
import DeliveryAddress from "../pages/DeliveryAddress";
import AddNote from "../pages/AddNote";
import AddPhoto from "../pages/AddPhoto";
import EditType from "../pages/EditType";
import EditConcierge from "../pages/EditConcierge";
import EditSafePlace from "../pages/EditSafePlace";
import EditPhone from "../pages/EditPhone";
import AddEmail from "../pages/AddEmail";
import VerifyProfileEmail from "../pages/VerifyProfileEmail";
import DeleteLoginAccountStatus from "../pages/DeleteLoginAccountStatus";
import EditPickupPoint from "../pages/EditPickupPoint";
import EditNeighbour from "../pages/EditNeighbour";
import AddNewAddress from "../pages/AddNewAddress";
import ProtectedAddressRoute from "../pages/Profile/ProtectedAddressRoute";

function AppRoutes() {
  return (
    <Routes>
      <Route path={Paths.SIGN_IN} element={<SignInLayout />}>
        <Route path="" element={<SignIn />} />
        <Route path="email" element={<VerifyEmail />} />
      </Route>
      <Route
        path={Paths.SIGN_UP}
        element={
          <ProtectedRoute type={SESSION_TYPE.ACCOUNT}>
            <SignUp />
          </ProtectedRoute>
        }
      />
      <Route
        path={Paths.CREATE_PROFILE}
        element={
          <ProtectedRoute type={SESSION_TYPE.PHONE_ACCOUNT}>
            <Onboarding />
          </ProtectedRoute>
        }
      />
      {/* <AuthRoute> */}
      <Route
        path={Paths.PROFILE}
        element={
          <ProtectedRoute type={SESSION_TYPE.CONSUMER_ACCOUNT}>
            <ProfileLayout />
          </ProtectedRoute>
        }
      >
        <Route index path="account" element={<AccountOverview />} />
        <Route path="personal" element={<PersonalInformation />}>
          <Route path="editPhone" element={<EditPhone />} />
          <Route path="addEmail" element={<AddEmail />} />
          <Route path="verifyEmail" element={<VerifyProfileEmail />} />
          <Route
            path="deleteLoginAccountStatus"
            element={<DeleteLoginAccountStatus />}
          />
        </Route>
        <Route path="addresses" element={<DeliveryAddresses />}>
          <Route path="add" element={<AddNewAddress />} />
          <Route
            path=":udprn"
            element={
              <ProtectedAddressRoute>
                <DeliveryAddress />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/type"
            element={
              <ProtectedAddressRoute>
                <EditType />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/concierge"
            element={
              <ProtectedAddressRoute>
                <EditConcierge />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/note"
            element={
              <ProtectedAddressRoute>
                <AddNote />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/photo"
            element={
              <ProtectedAddressRoute>
                <AddPhoto />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/pickupPoint"
            element={
              <ProtectedAddressRoute>
                <EditPickupPoint />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/pinpoint"
            element={
              <ProtectedAddressRoute>
                <DeliveryAddressPinPoint />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/safePlace"
            element={
              <ProtectedAddressRoute>
                <EditSafePlace />
              </ProtectedAddressRoute>
            }
          />
          <Route
            path=":udprn/neighbours"
            element={
              <ProtectedAddressRoute>
                <EditNeighbour />
              </ProtectedAddressRoute>
            }
          />
        </Route>
        <Route path="contact" element={<ContactYou />} />
        <Route path="" element={<Navigate to="account" replace />} />
      </Route>
      <Route path="/" element={<Navigate to={Paths.PROFILE} replace />} />

      <Route path="/somethingWentWrong" element={<SomethingWentWrong />} />
      {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default AppRoutes;
