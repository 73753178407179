import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import ToggleButton from "../ToggleButton";
import * as M from "../../../constants/strings";

const ConciergeToggle = ({ input, classes = {}, label }) => (
  <Form.Group controlId={input?.name} className={classes.container}>
    {label && (
      <Form.Label className={classes.label}>
        <Col
          xs={12}
          sm={12}
          className="d-flex justify-content-center text-center"
        >
          <span>{label}</span>
        </Col>
      </Form.Label>
    )}

    <Row className={classes.buttonsContainer}>
      <Col xs={6} sm={6}>
        <ToggleButton
          label={M.YES}
          isSelected={input.value}
          onClick={() => input.onChange(true)}
        />
      </Col>
      <Col xs={6} sm={6}>
        <ToggleButton
          label={M.NO}
          isSelected={!input.value}
          onClick={() => input.onChange(false)}
        />
      </Col>
    </Row>
  </Form.Group>
);

ConciergeToggle.propTypes = {
  input: PropTypes.object,
  classes: PropTypes.object,
};

export default ConciergeToggle;
