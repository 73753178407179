import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import { forwardRef } from "react";

const Input = forwardRef(
  ({ label, placeholder, input, meta = {}, required, classes, ...props }, ref) => {
    const inputLabel = required ? `${label} *` : label;

    return (
      <Form.Group as={Col} controlId={input?.name}>
        {inputLabel && <Form.Label className={classes?.label} >{inputLabel}</Form.Label>}
        <Form.Control
          ref={ref}
          placeholder={placeholder || inputLabel}
          {...input}
          {...props}
          isInvalid={meta.touched && !!meta.error}
        />
        <Form.Control.Feedback
          type="invalid"
          className={"d-block"}
          style={{ minHeight: 23 }}
        >
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
);

Input.propTypes = {
  ref: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  classes: PropTypes.object,
};

export default Input;
