import { get, isEmpty } from "lodash";
import { createSelector } from "reselect";
import { OnBoardingSelectors } from "./slice";
import { STEP_NAME } from "../../constants";

import { getAddressPoint } from "../../models/address";

export const getPersonalInformationValues = createSelector(
  OnBoardingSelectors.getValues,
  (values) => values[STEP_NAME.PERSONAL_INFORMATION]
);

export const getDeliveryAddressValues = createSelector(
  OnBoardingSelectors.getValues,
  (values) => values[STEP_NAME.DELIVERY_ADDRESS]
);

export const getActiveDeliveryAddress = createSelector(
  OnBoardingSelectors.getValues,
  OnBoardingSelectors.getReferences,
  (values, references) =>
    (references.addresses || []).find(
      (address) => address.udprn === values[STEP_NAME.DELIVERY_ADDRESS].udprn
    )
);

export const getConfirmDetailsValues = createSelector(
  OnBoardingSelectors.getValues,
  OnBoardingSelectors.getReferences,
  (values, references) => {
    const locationValues = values[STEP_NAME.PINPOINT_LOCATION];
    const neighbourValues = values[STEP_NAME.NEIGHBOURS];
    const deliveryAddressValues = values[STEP_NAME.DELIVERY_ADDRESS];
    const addresses = get(references, "addresses", []);
    const pickupLocations = get(references, "pickupLocations", []);
    const deliveryAddress = addresses.find(
      (address) => address.udprn === deliveryAddressValues.udprn
    );
    const neighbourAddresses = addresses.filter(
      (a) => a.udprn !== deliveryAddressValues.udprn
    );

    return {
      personalInformation: values[STEP_NAME.PERSONAL_INFORMATION],
      deliveryAddress,
      pinpointLocation: locationValues,
      neighbours: {
        preferred: addresses.find(
          ({ udprn }) => udprn === neighbourValues?.preferred
        ),
        avoid: addresses.find(({ udprn }) => udprn === neighbourValues?.avoid),
      },
      safePlace: values[STEP_NAME.SAFE_PLACE],
      pickupPoint: pickupLocations.find(
        ({ pickupLocation }) =>
          pickupLocation?.pickupLocationCode ===
          values.pickupPoint?.pickupLocationCode
      ),
      isNeighbourAddressesAvailable: !isEmpty(neighbourAddresses),
      isPinpointLocationAvailable: !!getAddressPoint(deliveryAddress),
      isPickupPointsAvailable: !isEmpty(pickupLocations),
      type: deliveryAddressValues?.type,
      concierge: deliveryAddressValues?.concierge,
    };
  }
);

export const getConfirmDetailsValid = createSelector(
  getConfirmDetailsValues,
  (values) => {
    const firstName = get(
      values,
      `${STEP_NAME.PERSONAL_INFORMATION}.firstName`
    );
    const lastName = get(values, `${STEP_NAME.PERSONAL_INFORMATION}.lastName`);
    const deliveryAddress = get(values, `${STEP_NAME.DELIVERY_ADDRESS}.udprn`);
    const preferredNeighbour = get(
      values,
      `${STEP_NAME.NEIGHBOURS}.preferred.udprn`
    );
    const avoidNeighbour = get(values, `${STEP_NAME.NEIGHBOURS}.avoid.udprn`);
    const safePlaceResponsibility = get(
      values,
      `${STEP_NAME.SAFE_PLACE}.safePlaceResponsibility`
    );
    const safePlaceCode = get(values, `${STEP_NAME.SAFE_PLACE}.code`);

    return (
      firstName &&
      lastName &&
      deliveryAddress &&
      deliveryAddress !== preferredNeighbour &&
      deliveryAddress !== avoidNeighbour &&
      (!safePlaceCode || (safePlaceCode && safePlaceResponsibility))
    );
  }
);
